import React, { useState,useEffect,useMemo } from 'react';
import { IconButton, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Auth } from 'aws-amplify';
import LoginIcon from '@mui/icons-material/Login';
import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from '@mui/icons-material/Logout';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import StyleIcon from '@mui/icons-material/Style';
import NoteIcon from '@mui/icons-material/Note';
import MenuOpen from '@mui/icons-material/MenuOpen'
import { ModalDialog} from '@tbirdcomponents/reactcomponents'; // Adjust the import path accordingly
import { useUtilityFunctions } from '../hooks/UtilityFunctionsHook'; // Adjust the import path accordingly
import ThemeGrid from './content/ThemeGrid';
import CreateEditPage from './content/CreateEditPage';
import PageSelector from './content/PageSelector';
import AuthenticationWrapper from './authentication/AuthenticationWrapper';
import AccountWrapper from './account/AccountWrapper';
function UserStatus({ onLoggedIn, onMenuEdit, onLoggedOut,onSavePage, pageModel,imageList,config }) {
    const [showLogin, setShowLogin] = useState(false); 
    const [editMenu, setEditMenu] = useState(false); 
    const [showPageEdit, setShowPageEdit] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    const [showSitePagesEdit, setShowSitePagesEdit] = useState(false);
    const [showStyleEdit, setShowStyleEdit] = useState(false);
    const { Authorization, WebSiteState, DatabaseProcessing, FileProcessing } = useUtilityFunctions();
    const [curUserName, setCurUserName] = useState(''); 
    const [isLoggedIn, setIsLoggedIn] = useState(Authorization.isUserLoggedIn()); 
    const [searchedPages, setSearchedPages] = useState([]);
    const [themesList, setThemesList] = useState([]);


    const pageSizes = [2, 5, 10, 20, 50, 100];


    //Get Possible Themese
    useEffect(() => {
        const getThemeNames = async () => {
            const folderPath = 'websites/' + config.Site.siteName + '/themes'; // Specify the folder path
            try {
                const result = await FileProcessing.getFileList(folderPath);
                if (result) {
                    console.log('Themes retrieved successfully:', result);
                    const newThemesList = result.results
                        .map(item => {
                            const parts = item.key.split('/');
                            return {
                                id: item.key,
                                name: parts[parts.length - 1].replace('.css', '') // Extract the filename from the path
                            };
                        })
                        .filter(item => item.name.trim() !== ''); // Filter out items with empty names
                    setThemesList(newThemesList);
                } else {
                    setThemesList([]);
                }
            } catch (error) {
                console.error('Themes retrieval failed:', error);
            }
        };
       getThemeNames();
    }, [FileProcessing, config.Site.siteName]);

    const handleShowLogin = (bool) => {
        setShowLogin(bool);
    };
    const handleShowAccount = (bool) => {
        setShowAccount(bool);
    };
    const handleSetCurUserName = (username) => {
        setCurUserName(username);
    };
    const handleLogouot = async (username, password) => {
        await Authorization.logoutUser();
        handleSetIsLoggedIn(false);
        if (onLoggedOut) {
            onLoggedOut();
        }
    };
    const handleSetIsLoggedIn = (bool) => {
        setIsLoggedIn(bool)
    };
    const handlePageEdit = () => {
        setShowPageEdit(true);
    };
    const handleSitePageEdit = () => {
        setShowSitePagesEdit(true);
    };
    const handleStyleEdit = () => {
        setShowStyleEdit(true);
    };

    const pageLayouts = useMemo(() => { return WebSiteState.getPageLayouts() ?? [] }, [WebSiteState]);
    const pageKeywords = useMemo(() => { return WebSiteState.getPageKeywords() ?? [] }, [WebSiteState]);
    const pageTopics = useMemo(() => { return WebSiteState.getPageTopics() ?? [] }, [WebSiteState]);


    //Cause Rerender when Page Model Chantes
    const curPageData = useMemo(() => {
        var newPageInfo = { ...pageModel };
        return newPageInfo;
    }, [pageModel]);

// Replace 'saveToFile' with your actual file-saving logic.
// The parameters could be folder path, file name, content, and content type.

    useEffect(() => {
        const getAuthUser = async () => {
            try {
                const authUser = await Authorization.getAuthenticatedUser();
                if (authUser) {
                    handleSetIsLoggedIn(true);
                    handleSetCurUserName(authUser.username);
                } else {
                    handleSetIsLoggedIn(false);
                    handleSetCurUserName('');
                }
                
            } catch (error) {
                // Handle error, could set user to null or show error message
                console.error('Failed to get authenticated user', error);
            }
        };

        getAuthUser();
    }, [Authorization]);

    const handleSavePages = (pageData) => {
       //Save Page information to database
        if (onSavePage) {
            onSavePage(pageData);
        }
        setShowPageEdit(false);
    };
    const handleMenuEdit = () => {
        if (onMenuEdit) {
            onMenuEdit(!editMenu);
            setEditMenu(!editMenu);
        }
    }

    const columns = [
        { field: "name", Header: "Name", Type: "Text" },
        { field: "type", Header: "Type", Type: "Text" },
    ];

    const handleOnSearch = async (criteria) => {
        console.log(criteria);
        const pageInfo = await DatabaseProcessing.searchPage(criteria);
        setSearchedPages(pageInfo);
        console.log(pageInfo);
        return pageInfo;
    };
    const handleOnSearchArticle = async (criteria) => {
        console.log(criteria);
        const articleInfo = await DatabaseProcessing.searchArticle(criteria);
        setSearchedPages(articleInfo);
        console.log(articleInfo);
        return articleInfo;
    };

    const handleArticleUpdated = async (input) => {
        //save articles to the db...
        console.log(input);
        if (input.id == null) {
            const newArticle = await DatabaseProcessing.upsertArticle(input);
            curPageData.articles.push(newArticle);
            return newArticle;
        }
        return input;
    };

    const uniqueID = () => {
        return Date.now();
    };

    return (
        <div>
            {!isLoggedIn && <IconButton color="inherit" onClick={handleShowLogin} style={{ cursor: 'pointer' }}>
                <LoginIcon /> <Typography variant="body1" style={{ marginLeft: 8 }}>
                    Login
                </Typography>
            </IconButton>}
            {isLoggedIn && <div>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <Tooltip title="Site Pages" placement="top"><NoteIcon onClick={handleSitePageEdit} /></Tooltip>
                    <Tooltip title="Edit Theme" placement="top"><StyleIcon onClick={handleStyleEdit} /></Tooltip>
                    <Tooltip title="Edit Page" placement="top"><AutoStoriesIcon onClick={handlePageEdit} /></Tooltip>
                    <Tooltip title="Edit Menu" placement="top"><MenuOpen onClick={handleMenuEdit} /></Tooltip>
                    <Tooltip title="Account" placement="top"><AccountCircleIcon onClick={handleShowAccount} /></Tooltip>                    
                    <Typography variant="body1" style={{ marginLeft: 8 }}>
                        {curUserName}
                    </Typography>
                    <Tooltip title="Logout" placement="top"><LogoutIcon onClick={handleLogouot} style={{ cursor: 'pointer' }} /></Tooltip>
                </div>
               
            </div>}
            {showLogin && <ModalDialog open={showLogin} onClose={() => { handleShowLogin(false); } }>
                <div>
                    <AuthenticationWrapper onLoggedIn={onLoggedIn} onShowLogin={handleShowLogin} onSetIsLoggedIn={handleSetIsLoggedIn} onSetCurUserName={handleSetCurUserName} />
                </div>
            </ModalDialog>}
            {showAccount && <ModalDialog open={showAccount} onClose={() => { handleShowAccount(false); }}>
                <div>
                    <AccountWrapper onShowAccount={handleShowAccount} />
                </div>
            </ModalDialog>}
            {showSitePagesEdit && <ModalDialog open={showSitePagesEdit} onClose={() => { setShowSitePagesEdit(false); }}>
                <div>
                    <PageSelector pages={searchedPages} pageSize={pageSizes} styleSheets={themesList} layouts={pageLayouts} articleColumns={columns}
                        topicsOptions={pageTopics} keywordOptions={pageKeywords}
                         onSearch={handleOnSearch} onSave={handleSavePages} />
                </div>
            </ModalDialog>}
            {showPageEdit && <ModalDialog open={showPageEdit} onClose={() => { setShowPageEdit(false); }}>
                <div>
                    <CreateEditPage
                        pageData={curPageData}
                        imageList={imageList}
                        styleSheets={themesList.map((x) => { return x.name })}
                        columns={columns}
                        layouts={pageLayouts}
                        keywordOptions={pageKeywords}
                        topicsOptions={pageTopics}
                        pageSize={pageSizes}
                        onSave={handleSavePages}
                        onArticleUpdated={handleArticleUpdated}
                        onArticleLookup={handleOnSearchArticle}
                        uniqueID={uniqueID}
                    />
                </div>
            </ModalDialog>}
            {showStyleEdit && <ModalDialog open={showStyleEdit} onClose={() => { setShowStyleEdit(false); }}>
                <div style={{ minWidth: '900px', minheight: '600px' } }>
                    <ThemeGrid themesList={themesList} ></ThemeGrid>
                </div>
            </ModalDialog>}
        </div>
    );
}
export default UserStatus;