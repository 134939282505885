import './App.css';
import React, { useEffect, useState } from 'react';
import { BaseDataContextProvider } from './hooks/baseData/BaseDataContextProvider';
import { Amplify } from 'aws-amplify';
import { BrowserRouter as Router, Route,Routes, useLocation } from 'react-router-dom';
import SiteContainer from './sitecontent/SiteContainer';
import { ThemeProvider } from '@mui/material/styles';
import { blueTheme, Spinner } from '@tbirdcomponents/reactcomponents';

function App() {
    
    function MainAppComponent() {
        const [configLoaded, setConfigLoaded] = useState(false); // State to track if config is loaded.
        const [config, setConfig] = useState(null); // Initialize with null or empty string, as it's supposed to be a string (site name).
        const [pageName, setPageName] = useState(null);
        const [articleName, setArticleName] = useState(null);
        const location = useLocation();

        useEffect(() => {
            const fetchConfig = async () => {
                try {
                    // Fetching the config file from the public folder.
                    const response = await fetch('/config.json');
                    const newConfig = await response.json();
                    if (!Amplify.configure.done) {
                        await Amplify.configure(newConfig.AWS);

                        // Set site name from the configuration.
                        setConfig(newConfig);
                    }

                    // Indicate that the configuration is loaded.
                    setConfigLoaded(true); // Setting true here means the config is successfully loaded.
                } catch (error) {
                    console.error("Error loading config:", error);
                    // Handle the error appropriately, e.g., set an 'error' state, and display it in the UI.
                }
            };

            fetchConfig();
        }, []); // Empty dependency array ensures this runs once when component mounts.



        useEffect(() => {
            const queryParams = new URLSearchParams(location.search);
            var qpageName = queryParams.get('page')??'Home';
            setPageName(qpageName);
            setArticleName(queryParams.get('article') ?? '');
            
        }, [location]);

        return (
            <ThemeProvider theme={blueTheme}>
                <BaseDataContextProvider value={config}>
                    {configLoaded && <SiteContainer pageName={pageName} articleName={articleName} config={config} />}
                </BaseDataContextProvider>
            </ThemeProvider>
        );
    }

    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<MainAppComponent />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
