import React, { useEffect, useState } from 'react';
import { useUtilityFunctions } from '../hooks/UtilityFunctionsHook'; // Adjust the import path accordingly
import PageContainer from './content/PageContainer';
import UserStatus from './UserStatus';
import { Spinner } from '@tbirdcomponents/reactcomponents'; // Adjust the import path accordingly
import HtmlContentRenderer from './content/HtmlContentRenderer'; // Adjust the import path accordingly

function SiteContainer({ pageName, articleName, config }) {
    const {Authorization, WebSiteState,FileProcessing, DatabaseProcessing } = useUtilityFunctions();
    const [siteLoaded, setSiteLoaded] = useState(false);//loaded state - make sure database and setup calls only fire once.
    const [pageType, setPageType] = useState(null); // Store the type of page instead of the component.
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [editMenu, setEditMenu] = useState(false);
    const [pageContent, setPageContent] = useState(null);
    
    const [pageModel, setPageModel] = useState(null);
    const [images, setImages] = useState([]);

    const [initialHtml, setInitialHtml] = useState('<div></div>');

    //Render InitialRender.html as a cold start html before the page and articles are loaded...
    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const response = await fetch('/initialRender.html');
                setInitialHtml(await response.text());
            } catch (error) {
                console.error("Error loading config:", error);
                setInitialHtml('<div>Could not Load Content</div>');
            }
        };

        fetchConfig();
    }, []); // Empty dependency array ensures this runs once when component mounts.

    //Get Initial Application State and Setup for render
    useEffect(() => {
        const getSiteInfo = async () => {
            WebSiteState.setShowSpinner(true);
            if (!siteLoaded) {
                setSiteLoaded(true);
                //Setup default values for page layouts, keywords, and topics dropdowns
                WebSiteState.setPageKeywords(await DatabaseProcessing.getKeywords());
                WebSiteState.setPageTopics(await DatabaseProcessing.getTopics());
                WebSiteState.setPageLayouts(await DatabaseProcessing.getLayouts());
                var affiliatedWebsites = await DatabaseProcessing.getWebsites();
                WebSiteState.setWebSites(affiliatedWebsites);
                const siteInfo = affiliatedWebsites
                    .filter(site => site.name === config.Site.website)
                    .map(site => site.id);

                // Assuming the goal is to set the ID of the first matching website (if any)
                if (siteInfo.length > 0) {
                    WebSiteState.setWebsiteID(siteInfo[0]);
                }

            }
            WebSiteState.setShowSpinner(false);
        }
        getSiteInfo();
    }, [Authorization, DatabaseProcessing, WebSiteState, config.Site.website, siteLoaded]);

    //retrieve page by name...
    useEffect(() => {
        const getSiteInfo = async () => {
            if (pageName) {
                let tempPageModel = {
                    articles: [],
                    description: '',
                    layout: 'Standard',
                    layoutid: 1,
                    name: pageName
                };
                try {
                    tempPageModel = await DatabaseProcessing.getPageByName(pageName);
                }
                catch (e) {
                    if (e.response.data === 'PageID not found') {
                        console.log('invalid page Name');
                    }

                    console.log(e);
                }
                //Map database Model to UI model... 
                tempPageModel.articles = Array.isArray(tempPageModel.articles) ? tempPageModel.articles : [];
                tempPageModel.keywords = Array.isArray(tempPageModel.keywords) ? tempPageModel.keywords : [];
                tempPageModel.topics = Array.isArray(tempPageModel.topics) ? tempPageModel.topics : 
                tempPageModel.layouts = Array.isArray(tempPageModel.layouts) ? tempPageModel.layouts : [];

                setPageModel(tempPageModel);
            }
        }
        getSiteInfo();
    }, [DatabaseProcessing, pageName]);

    useEffect(() => {
        const getPageInfo = async () => {
            try {
                const loggedInUser = await Authorization.getAuthenticatedUser();
                const siteType = config.Site.siteType;
                setPageType(siteType); // Just setting the type for conditional rendering below.
                setLoggedInUser(loggedInUser);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        };
        getPageInfo();
    }, [Authorization, config.Site.siteType]);

    //retrieve style themes

    useEffect(() => {
        const getImageList = async () => {
            const rootPath = `assets/${config.Site.siteName}/images`;
            const fileList = await FileProcessing.getFileList(rootPath);
            const imageList = fileList.results.map(item => ({
                url: `${config.Site.appURL}public/${item.key}`,
                alt: ''  // Assuming alt is empty for now; consider adding meaningful alt text if possible
            }));
            setImages(imageList);
        }

        getImageList();
    }, [FileProcessing, config.Site.appURL, config.Site.siteName]);

    const handleLogin = (loggedInUser) => {
        setLoggedInUser(loggedInUser);
    };

    const handleEditMenu = (editMode) => {
        setEditMenu(editMode);
    };

    const handleLogout = () => {
        setEditMenu(false);
    };

    const handleSavePage = async (updatePageInfo) => {
        await DatabaseProcessing.savePage(updatePageInfo);
        setPageModel({ ...updatePageInfo })
    }
    // Conditional rendering based on pageType - Page or Container... 
    useEffect(() => {
        const handlePageChange = async (page) => {

            let tempPageModel = {
                articles: [],
                description: '',
                layout: '',
                layoutid: 1,
                name: page.pageName
            };
            try {
                tempPageModel = await DatabaseProcessing.getPageByName(page.pageName);
                setPageModel(tempPageModel);
            }
            catch (e) {
                if (e.response.data === 'PageID not found') {
                    console.log('invalid page Name');
                }
                console.log(e);
            }
           
        };
        switch (pageType) {
            case 'BasicSite':
                if (pageModel == null) {
                    setPageContent(<HtmlContentRenderer htmlContent={initialHtml} />);
                } else {
                    setPageContent(<PageContainer editMenu={editMenu} pageModel={pageModel} imageList={images} config={config} onPageChange={handlePageChange} />);
                }
                break;
            // Consider adding more cases or a default case here.
            default:
                setPageContent(<HtmlContentRenderer htmlContent={initialHtml} />); // While Serverless arch is Warming render this content
        }
    }, [loggedInUser, editMenu, pageType, pageModel, images, config, DatabaseProcessing, initialHtml]);

    return (<div class='bodyStyle'>
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>
            <Spinner showSpinner={WebSiteState.getShowSpinner()} />
            <UserStatus user={loggedInUser} onLoggedIn={handleLogin} onMenuEdit={handleEditMenu} onLoggedOut={handleLogout} pageModel={pageModel} imageList={images ?? []} config={config} onSavePage={handleSavePage} />
        </div>
        <div>{pageContent}</div>
    </div>);
}

export default SiteContainer;