import { API } from 'aws-amplify';
export const DatabaseProcessing = {
    getWebsites: async () => {
        var pageInfo = await API.get('webArchApi', '/website/', {});
        return pageInfo;   
    },
    getPageById: async (pageId) => {
        var pageInfo = await API.get('webArchApi', '/page/' + pageId, {});
        return pageInfo;        
    },
    getArticleById: async (pageId) => {
        var pageInfo = await API.get('webArchApi', '/article/' + pageId, {});
        return pageInfo;
    },
    getPageByName: async (pageName) => {
        var pageInfo = await API.get('webArchApi', '/page/search/' + pageName, {});
        return pageInfo;
    },
    savePage: async (pageAndArticles) => {
        var pageInformation = { body: { ...pageAndArticles }};
        var pageInfo = await API.post('webArchApi', '/page', pageInformation);
        console.log(pageInfo);
        return pageInfo;
    },
    upsertArticle: async (article) => {
        var articleInfo = { body: { ...article } };
        var newArticleInfo = await API.post('webArchApi', '/article', articleInfo);
        console.log(newArticleInfo);
        return newArticleInfo;
    },
    searchPage: async (searchInfo) => {
        var tempSearchInfo = {
            body: {...searchInfo }
        };
        var pageInfo = [];
        try {
            pageInfo = await API.post('webArchApi', '/page/search', tempSearchInfo);
            console.log(pageInfo);
        }
        catch (e) {
            
        }
        console.log(pageInfo);
        return pageInfo;
    },
    searchArticle: async (searchInfo) => {
        var tempSearchInfo = {
            body: { ...searchInfo }
        };
        var pageInfo = await API.post('webArchApi', '/article/search', tempSearchInfo);
        console.log(pageInfo);
        return pageInfo;
    },
    getKeywords: async () => {
        var pageInfo = await API.get('webArchApi', '/keyword', {});
        return pageInfo;
    },
    getTopics: async () => {
        var pageInfo = await API.get('webArchApi', '/topic', {});
        return pageInfo;
    },
    getLayouts: async () => {
        var pageInfo = await API.get('webArchApi', '/layout', {});
        return pageInfo;
    },
};